import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Heading, Text } from 'grommet';
import React, { FC } from 'react';
import styled from 'styled-components';
import Button from './button';
import { Carousel } from './carousel';
import Intro from './intro';
import Link from './link';

interface FeaturedProjectsProps {
    items: any[];
    heading: string;
    intro: string;
}

const StyledItem = styled(Box)`
    position: relative;
    display: grid;
    justify-content: center;
    align-content: center;
    &:hover {
        cursor: pointer;
    }

    > * {
        grid-column: 1/-1;
        grid-row: 1/-1;
    }
    .caption {
        background-color: #3e3d3dd3;
        text-align: center;
        z-index: 8;

        a {
            color: #fff;
        }
    }
`;

// install Swiper components
const opts = {
    loop: true,
    spaceBetween: 16,
    slidesPerView: 1.2,
    navigation: true,
    scrollbar: false,
    mousewheel: false,
    keyboard: true,
    centeredSlides: true,
    breakpoints: {
        400: {
            slidesPerView: 1.5,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 'auto',
            spaceBetween: 16,
            scrollbar: false,
            centeredSlides: false,
            loop: false,
        },
    },
};

const FeaturedProjects: FC<FeaturedProjectsProps> = ({ items, heading, intro }) => (
    <Box background="white" width="100%" pad={{ vertical: 'large' }} className="light-1">
        {heading && <Intro heading={heading} intro={intro} />}
        <Carousel options={opts}>
            {items &&
                items.map(({ featuredImage, title, uri, id }) => {
                    const imageData = getImage(featuredImage.node.localFile);
                    return (
                        <StyledItem key={id} onClick={() => navigate(uri)}>
                            {imageData && (
                                <Link to={uri}>
                                    <GatsbyImage image={imageData} alt={featuredImage.altText} />
                                </Link>
                            )}

                            <Box margin="large" round="small" className="caption" justify="center" align="center">
                                <Heading level="3" textAlign="center" color="white" margin="none">
                                    <Link to={uri}>{title}</Link>
                                </Heading>
                            </Box>
                        </StyledItem>
                    );
                })}
        </Carousel>
        <Box pad={{ top: 'large' }} align="center">
            <Button primary to="/projects" label={<Text color="white">View All Projects</Text>} />
        </Box>
    </Box>
);

export default FeaturedProjects;
