import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, {
    A11y,
    Controller,
    Navigation,
    Scrollbar,
    Mousewheel,
    Keyboard,
    Virtual,
    Pagination,
    SwiperOptions,
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles/swiper-bundle.css';

const SliderWrap = styled.div`
    ${({ theme, inverted, padBottom, padSides, navOffset }) => css`
        --swiper-theme-color: ${theme.global.colors.brand.light};
        --swiper-navigation-size: 30px;
        --swiper-pagination-color: #fff;
        width: 100%;
        position: relative;
        overflow: hidden;

        .swiper-container {
            width: 100%;
            height: 100%;
 

            ${padSides &&
                css`
                    padding-left: 20px;
                    padding-right: 20px;
                `}
            ${padBottom &&
                css`
                    padding-bottom: 30px;
                `}
        }

        .swiper-slide {
     // text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      width: auto;
    }

        .swiper-scrollbar-drag {
         
            ${
                inverted
                    ? css`
                          background: #fff;
                      `
                    : css`
                          background: ${theme.global.colors.brand.light};
                      `
            }
        }

        .swiper-container-horizontal > .swiper-pagination-bullets {
            bottom: -10px;
        }
        .swiper-pagination-bullet {

          
            ${
                inverted
                    ? css`
                          background: #fff;
                      `
                    : css`
                          background: ${theme.global.colors.brand.light};
                      `
            }
          
        }

        .swiper-button-next {
            margin-right: 10px;
        }
        .swiper-button-prev {
            margin-left: 10px;
        }
        .swiper-button-next,
        .swiper-button-prev {
           // color: ${theme.global.colors.brand.light};
           background-color: ${theme.global.colors.brand.light};
           border: 2px solid #fff;
           color: #fff;
           border-radius: 50%;
           width: 38px;
           height: 38px;
           padding: 8px; 
           @media (min-width: 992px) {
               width: 48px;
               height: 48px;

           }

          
          
      
        }

        .swiper-button-prev:after, .swiper-button-next:after {
            font-size: 14px;
            @media (min-width: 992px) {
                font-size: 16px;
            }
        }


${navOffset &&
    css`
        @media (min-width: 1200px) {
            .swiper-button-prev {
                left: 50%;
                transform: translateX(-${navOffset});
            }

            .swiper-button-next {
                right: 50%;
                transform: translateX(${navOffset});
            }
        }
    `}
    `}
`;
// install Swiper components
SwiperCore.use([Controller, Navigation, Scrollbar, Mousewheel, Keyboard, A11y, Virtual, Pagination]);

interface CarouselProps {
    element?: any;
    options?: any;
    inverted?: boolean;
    padSides?: boolean;
    keyPrefix?: string;
    navOffset?: string;
}

export const Carousel: FC<CarouselProps> = ({
    element,
    options,
    children,
    inverted,
    padSides,
    keyPrefix = 'slider',
    navOffset,
}) => {
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const hasMount = useRef(false);

    useEffect(() => {
        if (controlledSwiper && !hasMount.current) {
            controlledSwiper.init();
            hasMount.current = true;
            console.log('GO Time');
            setTimeout(() => {
                controlledSwiper.update();
                console.log('GO updarted');
            }, 300);
        }
    }, [controlledSwiper]);

    const opts: SwiperOptions = {
        updateOnImagesReady: true,
        init: false,
        loop: true,
        spaceBetween: 16,
        slidesPerView: 1,
        navigation: true,
        scrollbar: { draggable: true },
        mousewheel: false,
        keyboard: true,
        watchOverflow: true,
        breakpoints: {
            400: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            768: {
                spaceBetween: 16,
                slidesPerView: 3,
                watchOverflow: true,
                scrollbar: {
                    hide: true,
                },
            },
        },
        ...options,
    };

    if (!children) {
        return null;
    }

    console.log(controlledSwiper);

    return (
        <div className={element}>
            <SliderWrap
                inverted={inverted}
                padSides={padSides}
                padBottom={opts?.pagination?.dynamicBullets}
                navOffset={navOffset}
            >
                <Swiper {...opts} onSwiper={setControlledSwiper}>
                    {React.Children.map(children, (slide, i) => (
                        <SwiperSlide key={`${keyPrefix}-${i}`}>
                            {React.cloneElement(slide as ReactElement, {})}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SliderWrap>
        </div>
    );
};

export default Carousel;
