import React, { FC } from 'react';

import ImageGallery from '@browniebroke/gatsby-image-gallery';
import { IImage } from '../utils/componentInterfaces';
import { MaxWidth } from './maxWidth';
import '@browniebroke/gatsby-image-gallery/dist/style.css';
import Intro from './intro';
import { Box } from 'grommet';

interface Props {
    gallery: [IImage];
    heading?: string;
    intro?: string;
}

const Gallery: FC<Props> = ({ gallery, heading, intro }) => {
    const images = gallery.map(({ localFile }) => localFile.childImageSharp);

    return (
        <Box pad={{ vertical: 'large' }} className="light-1">
            {heading && <Intro heading={heading} intro={intro} level="2" />}
            <MaxWidth pad={{ top: 'large' }}>
                <ImageGallery images={images} />
            </MaxWidth>
        </Box>
    );
};

export default Gallery;
