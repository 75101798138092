import React from 'react';

// import loadable from '@loadable/component';

import DumbFeaturedItems from '../../featuredItems';

export const InlineBlocks = ({ data }) => {
    const { heading, intro } = data;

    const items = data.items.map(item => ({
        featuredImage: { node: item.image },
        title: item.heading,
    }));
    return <DumbFeaturedItems heading={heading} intro={intro} items={items} />;
};
