import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';
import { IImage } from '../utils/componentInterfaces';
import Block from './block';
import Intro from './intro';

interface props {
    heading: string;
    items: IImage[];
}

const StyledLogoWall = styled.ul`
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding: 0;
    justify-content: center;
    align-items: center;

    li {
        margin: 8px;

        padding: 5px;
        flex-shrink: 0;
        flex-basis: 48%;

        @media (min-width: 768px) {
            flex-basis: auto;
        }
    }
`;

const StyledLogoWallImage = styled(GatsbyImage)`
    max-width: 180px;
    height: auto;
`;

const LogoWall: FC<props> = ({ heading, items }) => (
    <>
        <Block direction="column" className="light-1">
            {heading && <Intro heading={heading} />}
            <StyledLogoWall>
                {items.map(({ localFile }, index) => (
                    <li key={index}>
                        <StyledLogoWallImage image={localFile.childImageSharp.gatsbyImageData} />
                    </li>
                ))}
            </StyledLogoWall>
        </Block>
    </>
);

export default LogoWall;
