import React from 'react';

// import loadable from '@loadable/component';

import DumbSubPages from '../../subPages';

export const Listing = ({ data }) => (
    //
    <DumbSubPages heading={data.heading} intro={data.intro} childNodes={data.items} nodeType={data.nodeType} />
);
