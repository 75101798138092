import React, { FC } from 'react';
import { Box } from 'grommet';
import styled, { css } from 'styled-components';

export const StyledList = styled.ul`
    ${({ theme }) => css`
        list-style: none;
        text-align: center;
        margin: 0;
        padding: 0;

        li {
            font-size: ${theme.text.large.size};
            padding: ${theme.global.edgeSize.medium} ${theme.global.edgeSize.medium};
            position: relative;
        }
        li::after {
            border: 0;
            content: ' ';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: ${theme.global.colors.brand.light};
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            top: 100%;
        }

        li:first-child {
            padding-top: 0;
        }
        li:last-child {
            padding-bottom: 0;
        }

        li:last-child::after {
            content: none;
        }
    `}
`;

interface Item {
    title: string;
    link?: string;
}

interface ListProps {
    items: Item[];
}

const List: FC<ListProps> = ({ items }) => (
    <Box>
        {items && (
            <StyledList>
                {items.map(({ title, link }, index) => {
                    // if inks

                    if (link) {
                        return (
                            <li key={index}>
                                <a href={link}>{title}</a>
                            </li>
                        );
                    }

                    return <li key={index}>{title}</li>;
                })}
            </StyledList>
        )}
    </Box>
);

export default List;
