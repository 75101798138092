import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Heading, Text } from 'grommet';
import React, { FC } from 'react';
import styled from 'styled-components';
import Button from './button';
import { Carousel } from './carousel';

interface HeroSliderProps {
    gallery: any[];
    heading: string;
    buttonText: string;
    buttonLink: { uri: string };
    buttonText2: string;
    buttonLink2: { uri: string };
}

const StyledHero = styled(Box)`
    position: relative;
    max-width: 100vw;
    overflow: hidden;
    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 80px auto 80px;
        grid-template-rows: auto 90px auto;
        grid-template-rows: auto 120px auto;
        grid-template-columns: 1fr 680px 1fr;
    }
    @media (min-width: 992px) {
        grid-template-rows: auto 180px auto;
    }
`;
const StyledItem = styled(Box)``;

const StyledCarousel = styled(Box)`
    grid-column: 1/4;
    grid-row: 1/3;
`;

const StyledCta = styled(Box)`
    grid-row: 2/-1;
    grid-column: 2/3;
    z-index: 10;
    margin: 16px;
    margin-bottom: 0;
    @media (min-width: 768px) {
        margin: 0;
    }

    a {
        text-align: center;
    }
`;

// install Swiper components
const opts = {
    loop: true,
    spaceBetween: 16,
    slidesPerView: 1,
    navigation: true,
    scrollbar: false,
    mousewheel: false,
    keyboard: true,
    centeredSlides: true,
    autoplay: true,
    breakpoints: {
        400: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 16,
            scrollbar: false,
            centeredSlides: false,
            loop: false,
            autoplay: true,
        },
    },
};

const HeroSlider: FC<HeroSliderProps> = ({ gallery, heading, buttonText, buttonLink, buttonText2, buttonLink2 }) => (
    <StyledHero background="accent-3">
        <StyledCarousel>
            <Carousel options={opts}>
                {gallery?.length &&
                    gallery.map((image, i) => {
                        const imageData = getImage(image.localFile);
                        return (
                            <StyledItem key={i}>
                                {imageData && <GatsbyImage image={imageData} alt={image.altText} loading="eager" />}
                            </StyledItem>
                        );
                    })}
            </Carousel>
        </StyledCarousel>
        <StyledCta background={{ color: 'accent-1', dark: true }} pad="large" round="small">
            <Heading level={1} size="small" color="accent-2" textAlign="center" margin="none">
                {heading}
            </Heading>
            <Box direction="row-responsive" justify="center" align="center" gap="medium" margin={{ top: 'large' }}>
                {buttonLink?.uri && (
                    <Button
                        label={<Text textAlign="center">{buttonText}</Text>}
                        to={buttonLink.uri}
                        color="light-1"
                        reverse
                    />
                )}
                {buttonLink2?.uri && (
                    <Button
                        label={
                            <Text color="accent-1" textAlign="center">
                                {buttonText2}
                            </Text>
                        }
                        to={buttonLink2.uri}
                        color="white"
                        reverse
                        primary
                    />
                )}
            </Box>
        </StyledCta>
    </StyledHero>
);

export default HeroSlider;
