import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Box, Heading, Button as GButton } from 'grommet';
import { ILink } from '../utils/componentInterfaces';
import Button from './button';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';
import { MaxWidth } from './maxWidth';
import { HeaderPopupContext } from '../context';

export interface Props {
    buttonText: string;
    content: string;
    heading: string;
    link: ILink;
    style: string;
    linkOrPopup: 'popup' | 'link' | 'none';
}

const StyledContent = styled(Box)`
    text-align: center;
`;
const Cta: FC<Props> = ({ buttonText, intro, content, heading, link, style, linkOrPopup }) => {
    const { onOpen } = useContext(HeaderPopupContext);

    return (
        <Box
            background={style === 'standard' ? 'accent-1' : { color: 'brand', dark: true }}
            className={style === 'standard' ? 'accent-1' : 'brand'}
        >
            <MaxWidth align="center">
                <Box pad={{ vertical: 'large' }} align="center" width={{ max: '600px' }}>
                    <Heading level="2" margin={intro ? 'medium' : 'none'} textAlign="center">
                        {heading}
                    </Heading>
                    {intro && (
                        <Heading level="3" margin="none" textAlign="center">
                            {intro}
                        </Heading>
                    )}
                    <StyledContent margin={{ bottom: 'medium', top: 'medium' }}>
                        <HtmlContent dangerouslySetInnerHTML={createMarkup(content)} textAlign="center" />
                    </StyledContent>
                    {buttonText && linkOrPopup === 'link' && link && (
                        <Button label={buttonText} to={link.uri} color="light-1" reverse />
                    )}
                    {buttonText && linkOrPopup === 'popup' && (
                        <Button label={buttonText} to="/contact-us" color="light-1" reverse />
                        // <GButton label={buttonText} color="light-1" reverse onClick={onOpen} />
                    )}
                </Box>
            </MaxWidth>
        </Box>
    );
};

export default Cta;
