import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Box, Heading } from 'grommet';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import Intro from './intro';
import { IImage } from '../utils/componentInterfaces';
import Link from './link';
import { Carousel } from './carousel';

interface Item {
    id: string;
    link: any;
    slug: string;
    heading: string;
    image?: IImage;
}

interface props {
    heading: string;
    intro: string;
    items: Item[];
}

const StyledSliderWrap = styled(Box)`
    padding: 16px;
    margin-top: 24px;
    position: relative;
    max-width: 1040px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;
const StyledSliderItem = styled.div`
    ${props => css`
        position: relative;
        max-width: 420px;
        display: grid;
        justify-content: center;
        align-content: center;
        border-radius: : 8px;
        overflow: hidden;
      
        img {
            border-radius: 8px;   
        }
        &:hover {
        cursor: pointer;
    }

        > * {
            grid-column: 1/-1;
            grid-row: 1/-1;
        }
        > div:last-child {
            z-index: 8;
            background: rgba(132,181,156,0.82);
            border-radius: 8px; 
            &:hover {
                background: rgba(132,181,156,0.9); 
            }
        }
    `}
`;

const opts = {
    loop: false,
    spaceBetween: 16,
    slidesPerView: 1,
    navigation: true,
    scrollbar: false,
    mousewheel: false,
    keyboard: true,
    centeredSlides: false,
    watchOverflow: true,
    breakpoints: {
        400: {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 16,
            scrollbar: false,
            loop: false,
            watchOverflow: true,
            centeredSlides: false,
        },
    },
};

const featuredInlineBlocks: FC<props> = ({ heading, intro, items }) => (
    <>
        <Box direction="column" pad={{ vertical: 'large' }} className="accent-3" background="accent-3">
            <Intro heading={heading} intro={intro} />

            <StyledSliderWrap alignSelf="center" style={{ position: 'relative' }}>
                <Carousel options={opts}>
                    {items.map(({ image, heading: title, link: { uri } }, index) => (
                        <div key={`featured-${index}`}>
                            <StyledSliderItem className="featured-item" onClick={() => navigate(uri)}>
                                <div>
                                    {image && (
                                        <Link to={uri}>
                                            <GatsbyImage
                                                image={image.localFile.childImageSharp.gatsbyImageData}
                                                alt={title}
                                            />
                                        </Link>
                                    )}
                                </div>
                                <Box pad="medium" justify="center" alignContent="center">
                                    <Heading level="3" textAlign="center" color="white" margin="none">
                                        {title}
                                    </Heading>
                                </Box>
                            </StyledSliderItem>
                        </div>
                    ))}
                </Carousel>
            </StyledSliderWrap>
        </Box>
    </>
);

export default featuredInlineBlocks;
