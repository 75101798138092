import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Box, Heading } from 'grommet';
import Intro from './intro';
import { IImage } from '../utils/componentInterfaces';
import Button from './button';
import { StyledMask, StyledMaskImg } from './imageMasks';

import Soon from './soon';
import { Slider } from './slider';

interface Item {
    id: string;
    uri: string;
    slug: string;
    title: string;
    featuredImage?: IImage;
}

interface props {
    heading: string;
    intro: string;
    items: Item[];
}

const StyledSliderItem = styled.div`
    ${props => css`
        /* props.theme.global.edgeSize.medium
 */
        max-width: 300px;
    `}
`;

const FeaturedItems: FC<props> = ({ heading, intro, items }) => (
    <>
        <Box direction="column" pad={{ vertical: 'large' }} className="light-1">
            <Intro heading={heading} intro={intro} />

            <Box width={{ max: '1100px' }} pad={{ bottom: 'medium', top: 'large' }} alignSelf="center">
                <Slider>
                    {items.map(({ featuredImage, title, uri }, index) => (
                        <div key={index}>
                            <StyledSliderItem className="featured-item" width="300px">
                                <Box>
                                    {featuredImage && (
                                        <Box>
                                            <StyledMask
                                                size="300px 300px"
                                                imgSize="300px 300px"
                                                image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                            />
                                        </Box>
                                    )}

                                    {!featuredImage && (
                                        <Box>
                                            <Soon />
                                        </Box>
                                    )}

                                    <Box>
                                        <Heading level="3" textAlign="center" color="accent-2">
                                            {title}
                                        </Heading>
                                        {uri && (
                                            <Box direction="column" justify="between">
                                                <Button
                                                    label="Read more"
                                                    to={uri}
                                                    color="brand"
                                                    alignSelf="center"
                                                    reverse
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </StyledSliderItem>
                        </div>
                    ))}
                </Slider>
            </Box>
        </Box>
    </>
);

export default FeaturedItems;
