import React, { FC, useContext } from 'react';
import { Box, Heading, ResponsiveContext, Text } from 'grommet';
import styled, { css } from 'styled-components';

import BackgroundImage from 'gatsby-background-image';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';
import Block from './block';

interface Props {
    title: string;
    subHeading?: string;
    standoutHeading?: boolean;
    showCheckATrade?: boolean;
    intro?: string;
    image?: any;
    widget?: any;
}

const StyledH1 = styled(Heading)`
    ${({ theme }) => css`
        margin-top: 0;

        span {
            color: ${theme.global.colors.brand.light};
        }
    `}
`;

const StyledPageHeading = styled(Box)`
    position: relative;
    z-index: 10;

    .hero-bg {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &[style] {
            position: absolute !important;
        }
    }
    ${props =>
        props.standoutHeading &&
        css`
            /* ${console.log(props.theme)} */
            min-height: 400px;
            @media(min-width: 768px) {
            min-height: 540px;
            }
            @media(min-width: 992px) {
            min-height: 640px;

            }
        `}
`;

const PageHeading: FC<Props> = ({ title, subHeading, standoutHeading, intro, image, widget, showCheckATrade }) => {
    const size = useContext(ResponsiveContext);

    const headerWidget = widget || null;

    const MainBlock = () => (
        <Block direction="column" vertical={size === 'small' ? 'large' : 'xlarge'}>
            <Box
                width={{ max: '800px' }}
                style={{ textAlign: standoutHeading ? 'left' : 'center' }}
                align="center"
                alignSelf="center"
                background={{ color: '#fff', opacity: 0.8 }}
                pad={standoutHeading && 'medium'}
                animation="slideUp"
            >
                <StyledH1 level={1} color="accent-2" textAlign="center">
                    {title}
                </StyledH1>
                {subHeading && (
                    <Text as="p" color="brand" textAlign="center" margin="none" weight="bold">
                        {subHeading}
                    </Text>
                )}

                {intro && (
                    <Text textAlign="center">
                        <HtmlContent dangerouslySetInnerHTML={createMarkup(intro)} />
                    </Text>
                )}
                {headerWidget && <>{headerWidget}</>}
                {showCheckATrade && (
                    <Box background="white" pad="small">
                        <a
                            href="https://www.checkatrade.com/trades/ecoroofingse"
                            target="_blank"
                            rel="noreferrer nofollow"
                        >
                            <img
                                src="http://www.checkatrade.com/Images/ProudMembersOfCAT-230x60.jpg"
                                alt="Checkatrade information for Eco Roofing SE"
                            />
                        </a>
                    </Box>
                )}
            </Box>
        </Block>
    );

    return (
        <StyledPageHeading
            standoutHeading={standoutHeading}
            justify="center"
            className={standoutHeading ? 'page-heading standout' : 'light-1 page-heading'}
            elevation={standoutHeading ? 'medium' : false}
        >
            {standoutHeading && image ? (
                <BackgroundImage
                    className="hero-bg"
                    fluid={image.localFile.childImageSharp.fluid}
                    backgroundColor="#ccc"
                >
                    <MainBlock />
                </BackgroundImage>
            ) : (
                <MainBlock />
            )}
        </StyledPageHeading>
    );
};
export default PageHeading;
