import React, { FC } from 'react';
import { Box, Paragraph } from 'grommet';
import styled, { css } from 'styled-components';
import { IImage } from '../utils/componentInterfaces';
import Block from './block';
import { StyledMask } from './imageMasks';
import bgImage from '../images/quoteBg.svg';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';

const StyledBlock = styled(Block)`
    .block {
        position: relative;
        background-image: url('${bgImage}');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 20% auto;

    }
`;
const StyledQuoteBlock = styled(Box)`
    ${({ theme }) => css`
        padding: ${theme.global.edgeSize.medium} 0 0 10%;
    `}
`;
interface props {
    author: string;
    quoteText: string;
    image: IImage;
}

const Quote: FC<props> = ({ author, quoteText, image }) => (
    <StyledBlock className="light-1">
        <StyledQuoteBlock justify="center">
            <Paragraph fill size="large" color="accent-2">
                <HtmlContent dangerouslySetInnerHTML={createMarkup(quoteText)} />
            </Paragraph>
            <Paragraph>{author}</Paragraph>
        </StyledQuoteBlock>
        {image && image.localFile && image.localFile.childImageSharp && (
            <Box width={{ max: '400px' }} basis="100%" flex={{ shrink: 0, grow: 1 }}>
                <StyledMask
                    image={image.localFile.childImageSharp.gatsbyImageData}
                    size="100% 100%"
                    imgSize="100% 100%"
                    loading="eager"
                />
            </Box>
        )}
    </StyledBlock>
);

export default Quote;
