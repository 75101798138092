import React, { FC, useContext } from 'react';
import { Box, Heading, ResponsiveContext, Button as GButton } from 'grommet';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ILink, IImage } from '../utils/componentInterfaces';
import Button from './button';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';
import { StyledMask } from './imageMasks';
import { HeaderPopupContext } from '../context';
import { MaxWidth } from './maxWidth';

interface props {
    buttonText: string;
    content: string;
    heading: string;
    intro?: string;
    link: ILink;
    image: IImage;
    position: string;
    style: string;
    maskImage?: boolean;
    linkOrPopup: 'popup' | 'link' | 'none';
    noWrap?: boolean;
}

const TheBlock: FC<any> = ({
    buttonText,
    content,
    heading,
    intro,
    link,
    image,
    position,
    style,
    maskImage,
    linkOrPopup,
}) => {
    const size = useContext(ResponsiveContext);
    const { onOpen } = useContext(HeaderPopupContext);
    let direction = 'column';

    if (size !== 'small' && position === 'left') {
        direction = 'row';
    }
    if (size !== 'small' && position === 'right') {
        direction = 'row-reverse';
    }
    return (
        <MaxWidth direction={direction} gap="large">
            {image && (
                <Box
                    width={{ max: '400px' }}
                    basis="100%"
                    flex={{ shrink: 0, grow: 1 }}
                    animation={position === 'right' ? 'slideLeft' : 'slideRight'}
                >
                    {maskImage ? (
                        <StyledMask
                            image={image?.localFile.childImageSharp?.gatsbyImageData}
                            alt={image.altText}
                            size="100% 100%"
                            imgSize="100% 100%"
                            flipped={position === 'left'}
                            style={{ position: 'sticky', top: 0 }}
                        />
                    ) : (
                        <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData} alt={image.altText} />
                    )}
                </Box>
            )}

            <Box direction="column">
                <Heading level="2" color="accent-2">
                    {heading}
                </Heading>
                {intro && (
                    <Heading level="3" color="accent-2">
                        {intro}
                    </Heading>
                )}
                {content && <HtmlContent marginBottom dangerouslySetInnerHTML={createMarkup(content)} />}

                {buttonText && linkOrPopup !== 'popup' && link && (
                    <Button
                        label={buttonText}
                        to={link.uri}
                        color={style === 'standard' ? 'brand' : 'brand'}
                        reverse
                        alignSelf="start"
                    />
                )}
                {buttonText && linkOrPopup === 'popup' && (
                    <GButton
                        label={buttonText}
                        color={style === 'standard' ? 'brand' : 'brand'}
                        reverse
                        onClick={onOpen}
                        alignSelf="start"
                    />
                )}
            </Box>
        </MaxWidth>
    );
};

const ImageTextBlock: FC<props> = props => {
    const { style, noWrap } = props;

    return (
        <>
            {noWrap ? (
                <TheBlock {...props} />
            ) : (
                <Box
                    className={style === 'standard' ? 'light-1' : 'brand'}
                    pad={{ vertical: 'large' }}
                    background={
                        style === 'standard'
                            ? null
                            : {
                                  color: 'light-2',
                                  opacity: 0.9,
                                  dark: false,
                              }
                    }
                >
                    <TheBlock {...props} />
                </Box>
            )}
        </>
    );
};

export default ImageTextBlock;
