import React, { FC } from 'react';
import Block from './block';
import Intro from './intro';
import List from './list';

interface Item {
    item: string;
}

interface props {
    heading: string;
    intro: string;
    items: Item[];
}

const ListBlock: FC<props> = ({ heading, intro, items }) => {
    const list = items.map(item => ({ title: item.item }));

    return (
        <Block direction="column" className="light-1">
            <Intro heading={heading} intro={intro} />
            <List items={list} />
        </Block>
    );
};

export default ListBlock;
