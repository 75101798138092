import React, { useState, useRef } from 'react';
import Swiper from 'react-id-swiper';
import '../assets/swiper.css';
import styled from 'styled-components';

const SwiperWrap = styled.div`
    position: relative;
    width: 100%;
`;

export const Slider = ({ children }) => {
    const ref = useRef(null);

    const params = {
        slidesPerView: 'auto',
        spaceBetween: 24,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            768: {
                spaceBetween: 24,
                slidesPerView: 3,
            },
            640: {
                slidesPerView: 2,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 12,
            },
        },
    };
    return (
        <SwiperWrap>
            <Swiper ref={ref} {...params}>
                {children}
            </Swiper>
        </SwiperWrap>
    );
};
