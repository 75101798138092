import React, { FC } from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import capitalize from '../../utils/capitalize';
import * as AllComponents from './components';

interface ComponentRendererProps {
    components: any;
    nodeID: string;
    nodeType: string;
    childNodes: any[];
}

interface DynamicComponentProps {
    alias: string;
    data: any;
    widgets?: any;
}

interface ComponentProps {
    data: any;
    widgets?: any[];
}

export const StyledComponents = styled(Box)`
    z-index: 10;
    .brand + .brand,
    .accent-1 + .accent-1,
    .light-2 + .light-2,
    .light-1 + .light-1 {
        padding-top: 0;
        .block {
            padding-top: 0;
        }
    }
`;

const buildComponents = (items, nodeID, childNodes, nodeType) =>
    items && Array.isArray(items) && items.length >= 1
        ? items.map(({ fieldGroupName, ...rest }, index) => (
              <DynamicComponent
                  key={`gc-${fieldGroupName}-${index}`}
                  data={{ ...rest, nodeID, childNodes, nodeType }}
                  alias={fieldGroupName}
              />
          ))
        : null;

const DynamicComponent: FC<DynamicComponentProps> = ({ alias, data, widgets }) => {
    // 1. Generate the component name
    // const componentName = useMemo(() => capitalize(alias), [alias]);
    const componentName = capitalize(alias.replace(/[a-zA-Z]+_Contentitems_PageContent_/gi, ''));

    // 2. Grab the layout
    const TagName: FC<ComponentProps> = AllComponents[componentName];

    // 3. Check it actually exists
    if (!TagName) {
        console.log(`*** COMPONENT not found: ${componentName} ***`);

        return process.env.NODE_ENV === 'development' ? (
            <div>
                <p>
                    <b>*** COMPONENT not found: {componentName} ***</b>
                </p>
                <pre>{JSON.stringify(data, null, 2)}</pre>
                <hr />
            </div>
        ) : null;
    }

    // 4. Does the component have sub components
    const widgetComponents = Array.isArray(widgets)
        ? widgets.map(item => DynamicComponent({ alias: item.elementAlias, data: item.data, widgets: item.widgets }))
        : null;

    // return
    return <TagName data={data} widgets={widgetComponents} />;
};

const ComponentRenderer: FC<ComponentRendererProps> = ({ components, nodeID, childNodes, nodeType }) => {
    // 1. generate components for inside

    const widgetComponents = buildComponents(components, nodeID, childNodes, nodeType);

    // 2. Return
    return (
        <StyledComponents direction="column" className="component-renderer">
            {widgetComponents}
        </StyledComponents>
    );
};

export default ComponentRenderer;
