import React from 'react';

// import loadable from '@loadable/component';

import DumbSubPages from '../../subPages';

export const SubPages = ({ data }) => (
    //
    <DumbSubPages {...data} />
);
