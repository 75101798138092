import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import Intro from './intro';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';

interface props {
    content: string;
    heading?: string;
    subHeading?: string;
    level?: 2 | '1' | '2' | '3' | '4' | '5' | '6' | 1 | 3 | 4 | 5 | 6 | undefined;
    widget?: any;
    categories?: any;
    tags?: any;
}

const StyledBox = styled(Box)`
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    @media (min-width: 1080px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

const ContentBlock: FC<props> = ({ content, heading, subHeading, level, widget }) => {
    const postWidget = widget || null;
    return (
        <Box pad={{ vertical: 'large' }} className="light-1">
            {heading && <Intro heading={heading} intro={subHeading} level={level} />}
            {postWidget}
            <StyledBox clasName="block">
                <HtmlContent dangerouslySetInnerHTML={createMarkup(content)} />
            </StyledBox>
        </Box>
    );
};

export default ContentBlock;
