import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Text } from 'grommet';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Carousel } from './carousel';

interface ImageSliderProps {
    gallery: any[];
}

const StyledItem = styled(Box)`
    position: relative;

    .caption {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .tag {
        position: absolute;
        bottom: 10px;
        right: 10px;
        text-align: right;
    }
`;

// install Swiper components
const opts = {
    loop: true,
    spaceBetween: 16,
    slidesPerView: 1.2,
    navigation: true,
    scrollbar: false,
    mousewheel: false,
    keyboard: true,
    centeredSlides: true,
    breakpoints: {
        400: {
            slidesPerView: 1.5,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 'auto',
            spaceBetween: 16,
            scrollbar: false,
            centeredSlides: false,
            loop: false,
        },
    },
};

const ImageSlider: FC<ImageSliderProps> = ({ gallery }) => (
    <Box background="white" width="100%">
        <Carousel options={opts}>
            {gallery &&
                gallery.map((image, i) => {
                    const imageData = getImage(image.localFile);
                    return (
                        <StyledItem key={i}>
                            {imageData && <GatsbyImage image={imageData} alt={image.altText} loading="eager" />}
                        </StyledItem>
                    );
                })}
        </Carousel>
    </Box>
);

export default ImageSlider;
