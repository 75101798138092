import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IImage } from '../utils/componentInterfaces';
import { MaxWidth } from './maxWidth';

interface Props {
    image: IImage;
}

const StandoutImage: FC<Props> = ({ image }) => (
    <div className="light-1">
        <MaxWidth pad={{ vertical: 'large' }} className="block">
            {image && <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} />}
        </MaxWidth>
    </div>
);

export default StandoutImage;
