import React from 'react';
import { Box } from 'grommet';
import MaxWidth from './maxWidth';
import Intro from './intro';
import Block from './block';

const getPadding = option => {
    let size = option;
    if (size.indexOf(`%`) > -1) {
        return size;
    }
    if (size === `widescreen`) {
        return `56.25%`;
    }
    if (size === `standard`) {
        return `75%`;
    }
    if (size.indexOf(`:`) > -1) {
        size = size.split(`:`);
        size = size[1] / size[0];
        size *= 100;
        return `${size}%`;
    }
    return size;
};

function getId(input) {
    let str = input;
    str = str.split(`/`);
    str = str.pop();
    if (str.indexOf(`?v=`) > -1) {
        str = str.split(`?v=`)[1];
    }
    str = str.split(`?`)[0];
    str = str.split(`&`)[0];
    return str;
}

const Video = ({ embed, aspectRatio = 'widescreen', heading, intro, ...props }) => (
    <Box pad={{ vertical: 'large' }} className="light-1">
        <Block direction="column">
            {heading && <Intro heading={heading} intro={intro} level={2} />}
            <div
                style={{
                    position: `relative`,
                    paddingBottom: getPadding(aspectRatio),
                    width: `100%`,
                    height: 0,
                }}
                {...props}
            >
                <iframe
                    src={`https://www.youtube.com/embed/${getId(embed)}`}
                    title={heading || 'Video'}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    style={{
                        position: `absolute`,
                        top: 0,
                        left: 0,
                        width: `100%`,
                        height: `100%`,
                    }}
                />
            </div>
        </Block>
    </Box>
);
export default Video;
