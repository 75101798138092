import React, { FC } from 'react';
import { Box } from 'grommet';
import { BackgroundType } from 'grommet/utils';
import { MaxWidth } from './maxWidth';

interface BlockProps {
    direction?: 'row-responsive' | 'row' | 'column' | 'row-reverse' | 'column-reverse';
    className?: string;
    background?: BackgroundType;
    vertical?: any;
}
const Block: FC<BlockProps> = ({
    children,
    direction = 'row-responsive',
    className,
    background,
    vertical = 'xlarge',
}) => (
    <MaxWidth align="center" className={className} background={background}>
        <Box pad={{ vertical }} direction={direction} gap="large" className="block" width="100%">
            {children}
        </Box>
    </MaxWidth>
);

export default Block;
